<template>
  <div id="storage-performance">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12 xs12>
          <v-widget :title="$vuetify.lang.t('$vuetify.storage.create')">
            <div slot="widget-content">
              <v-form ref="itemForm" lazy-validation v-model="valid">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-select
                        :items="employers"
                        v-model="item.employer_id"
                        prepend-icon="person"
                        required
                        clearable
                        :label="$vuetify.lang.t('$vuetify.user.employer')"
                        :placeholder="
                          $vuetify.lang.t('$vuetify.user.selectOneEmployer')
                        "
                        class="input-group--focused"
                        :rules="rules.notEmpty"
                        item-value="id"
                        item-text="title"
                      >
                        <v-tooltip bottom slot="prepend">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-on="on"
                              v-bind="attrs"
                              @click="dialogEmployers = true"
                            >
                              <v-icon>create</v-icon>
                            </v-btn>
                          </template>
                          <span>{{
                            $vuetify.lang.t('$vuetify.buttons.edit')
                          }}</span>
                        </v-tooltip>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        :items="manufacturers"
                        v-model="item.manufacturers"
                        tags
                        chips
                        multiple
                        return-object
                        required
                        :label="$vuetify.lang.t('$vuetify.manufacturer.many')"
                        :placeholder="
                          $vuetify.lang.t('$vuetify.manufacturer.selectMany')
                        "
                        class="input-group--focused"
                        :rules="rules.notEmpty"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-menu
                        ref="datePicker"
                        v-model="datePicker"
                        :close-on-content-click="false"
                        :return-value.sync="item.period"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.period"
                            :label="$vuetify.lang.t('$vuetify.dates.period')"
                            :placeholder="
                              $vuetify.lang.t('$vuetify.dates.selectPeriod')
                            "
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            :rules="rules.notEmpty"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          first-day-of-week="1"
                          range
                          v-model="item.period"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="datePicker = false"
                            >{{
                              $vuetify.lang.t('$vuetify.buttons.cancel')
                            }}</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.datePicker.save(item.period)"
                            >{{ $vuetify.lang.t('$vuetify.buttons.ok') }}
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-btn
                        rounded
                        color="success"
                        :disabled="!valid"
                        :loading="createLoading"
                        @click="create"
                      >
                        <v-icon>mdi-plus</v-icon>
                        {{ $vuetify.lang.t('$vuetify.buttons.create') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </div>
          </v-widget>
        </v-flex>
        <v-flex lg12 xs12>
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn small rounded @click="getItems" class="mr-2">
                <v-icon>mdi-refresh</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.refresh') }}
              </v-btn>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="compHeaders"
                :items="items"
                fixed-header
                class="elevation-1"
                item-key="id"
                :loading="tableLoading"
                :options.sync="options"
                :server-items-length="totalItems"
              >
                <template v-slot:item.id="{ item }">
                  {{ item.view_id }}
                </template>
                <template v-slot:item.employer_id="{ item }">
                  {{ item.employer.title }}
                </template>

                <template v-slot:item.manufacturers="{ item }">
                  <div class="ma-4">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $vuetify.lang.t('$vuetify.manufacturer.one') }}
                            </th>
                            <th class="text-left">
                              {{ $vuetify.lang.t('$vuetify.different.items') }}
                            </th>
                            <th class="text-left">
                              {{ $vuetify.lang.t('$vuetify.order.orderMany') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="manufacturer in item.manufacturers"
                            :key="manufacturer.id"
                          >
                            <td>{{ manufacturer.text }}</td>
                            <td>{{ manufacturer.items }}</td>
                            <td>{{ manufacturer.orders }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </template>

                <template v-slot:item.working_hours="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.working_hours"
                    large
                    persistent
                    @save="save(props.item)"
                  >
                    {{ props.item.working_hours }}
                    <template v-slot:input>
                      <div class="mt-4 title">
                        {{
                          $vuetify.lang.t('$vuetify.different.setWorkingHours')
                        }}
                      </div>
                      <v-text-field
                        v-model="props.item.working_hours"
                        :label="$vuetify.lang.t('$vuetify.dates.hours')"
                        single-line
                        autofocus
                        type="number"
                        step="0.1"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:item.calc_time="{ item }">
                  {{ item.calc_time_pretty }}
                </template>

                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="destroy(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                      $vuetify.lang.t('$vuetify.buttons.delete')
                    }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">{{
                $vuetify.lang.t('$vuetify.different.deleteItemConfirm')
              }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogDelete = false"
                  >{{ $vuetify.lang.t('$vuetify.buttons.cancel') }}</v-btn
                >
                <v-btn color="blue darken-1" text @click="destroyConfirm">{{
                  $vuetify.lang.t('$vuetify.buttons.ok')
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-model="dialogEmployers"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogEmployers = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            $vuetify.lang.t('$vuetify.user.employers')
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-form ref="employerForm" lazy-validation v-model="validEmployer">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="employer.title"
                  :label="$vuetify.lang.t('$vuetify.user.name')"
                  :rules="rules.max255"
                  required
                  counter
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  rounded
                  color="success"
                  :disabled="!validEmployer"
                  :loading="createEmployerLoading"
                  @click="createEmployer"
                >
                  <v-icon>mdi-plus</v-icon>
                  {{ $vuetify.lang.t('$vuetify.buttons.create') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-data-table
          :headers="compHeadersEmployers"
          :items="employers"
          fixed-header
          class="elevation-1"
          item-key="id"
          :items-per-page="10"
          :loading="employersLoading"
        >
          <template v-slot:item.title="props">
            <v-edit-dialog
              :return-value.sync="props.item.title"
              large
              persistent
              @save="saveEmployer(props.item)"
            >
              {{ props.item.title }}
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.title"
                  :label="$vuetify.lang.t('$vuetify.user.name')"
                  single-line
                  autofocus
                  counter
                  :rules="rules.max255"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="destroyEmployer(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $vuetify.lang.t('$vuetify.buttons.delete') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <AppLoader :dialog.sync="loading"></AppLoader>
  </div>
</template>

<script>
import VWidget from '@/components/VWidget'
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    VWidget,
    AppLoader
  },
  data() {
    return {
      loading: false,
      tableLoading: false,
      createLoading: false,
      createEmployerLoading: false,
      employersLoading: false,
      dialogDelete: false,
      dialogEmployers: false,
      valid: true,
      validEmployer: true,
      validEditEmployer: true,
      items: [],
      employers: [],
      search: null,
      datePicker: false,
      manufacturers: [],
      item: {
        employer_id: null,
        manufacturers: null,
        period: null
      },
      emptyItem: {
        employer_id: null,
        manufacturers: null,
        period: null
      },
      employer: {
        title: null
      },
      destroyItem: null,
      rules: {
        notEmpty: [
          (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required')
        ],
        max255: [
          (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
          (v) =>
            (v && v.length <= 255) ||
            this.$vuetify.lang.t('$vuetify.validation.max255')
        ]
      },
      totalItems: 0,
      headersEmployers: [
        { text: this.$vuetify.lang.t('$vuetify.user.name'), value: 'title' },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ],
      options: {}
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.menu.storagePerformance')
    }
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    },
    compHeaders() {
      return [
        { text: 'ID', value: 'id' },
        {
          text: this.$vuetify.lang.t('$vuetify.user.employer'),
          value: 'employer_id'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.manufacturer.many'),
          value: 'manufacturers',
          sortable: false
        },
        { text: this.$vuetify.lang.t('$vuetify.dates.from'), value: 'from' },
        { text: this.$vuetify.lang.t('$vuetify.dates.to'), value: 'to' },
        {
          text: this.$vuetify.lang.t('$vuetify.different.items'),
          value: 'items'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.workingHours'),
          value: 'working_hours'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.timeForItem'),
          value: 'calc_time'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    },
    compHeadersEmployers() {
      return [
        { text: this.$vuetify.lang.t('$vuetify.user.name'), value: 'title' },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  watch: {
    'item.manufacturers': function(val) {
      if (!val) this.item.manufacturers = null
    },
    options: {
      handler() {
        this.getItems()
      },
      deep: true
    },
    dialogDelete(val) {
      if (!val) this.destroyItem = null
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    create() {
      if (this.$refs.itemForm.validate()) {
        this.loading = true

        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/performance/store',
            this.item,
            this.compAuthHeader
          )
          .then((response) => {
            if (response.data.success) {
              this.item = Object.assign({}, this.emptyItem)
              this.$refs.itemForm.resetValidation()
              this.getItems()
            }
            this.loading = false
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
          })
      }
    },
    createEmployer() {
      if (this.$refs.employerForm.validate()) {
        this.createEmployerLoading = true

        this.$axios
          .post(
            this.$store.getters.getApiUrl + 'api/employer/store',
            this.employer,
            this.compAuthHeader
          )
          .then((response) => {
            if (response.data.success) {
              this.employer.title = null
              this.$refs.employerForm.resetValidation()
              this.getEmployers()
            }
            this.createEmployerLoading = false
          })
          .catch((error) => {
            console.log(error)
            this.createEmployerLoading = false
          })
      }
    },
    init() {
      this.loading = true

      let headers = this.compAuthHeader

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/performance/init',
          {},
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            this.manufacturers = response.data.manufacturers
            this.employers = response.data.employers
          }
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    getItems() {
      this.tableLoading = true
      let data = {
        search: this.search,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort_by: this.options.sortBy[0],
        descending: this.options.sortDesc[0]
        //filters: this.filters
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/performance',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data.data
            this.totalItems = response.data.meta.total
          }

          this.tableLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.tableLoading = false
        })
    },
    getEmployers() {
      this.employersLoading = true

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/employers',
          {},
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.employers = response.data.employers
          }
          this.employersLoading = false
        })
        .catch((error) => {
          console.log(error)
          this.employersLoading = false
        })
    },
    save(item) {
      let data = {
        id: item.id,
        working_hours: item.working_hours
      }
      let editedIndex = this.items.indexOf(item)

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/performance/update',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            Object.assign(this.items[editedIndex], response.data.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveEmployer(item) {
      let data = {
        id: item.id,
        title: item.title
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/employer/update',
          data,
          this.compAuthHeader
        )
        .then((response) => {})
        .catch((error) => {
          console.log(error)
        })
    },
    destroy(item) {
      this.destroyItem = item
      this.dialogDelete = true
    },
    destroyConfirm() {
      let data = {
        id: this.destroyItem.id
      }

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/performance/destroy',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.getItems()
          }
          this.destroyItem = null
          this.dialogDelete = false
        })
        .catch((error) => {
          console.log(error)
          this.destroyItem = null
          this.dialogDelete = false
        })
    },
    destroyEmployer(item) {
      let data = {
        id: item.id
      }
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/employer/destroy',
          data,
          this.compAuthHeader
        )
        .then((response) => {
          if (response.status === 200) {
            this.getEmployers()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
